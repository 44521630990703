import React from "react";
import "./ExperienceAccordion.css";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";
import { experience } from "../../portfolio";

// import ExperienceCard from "../../components/experienceCard/ExperienceCard.js";
// import "./ExperienceAccordion.css";
// import { Accordion, Panel } from "baseui/accordion";
// import { DarkTheme, LightTheme, ThemeProvider } from "baseui";

function ExperienceAccordion(props) {
  const theme = props.theme;

  console.log(experience.experiences, "theme");

  return (
    <>
      <Timeline lineColor={theme.text} animate={true}>
        {experience.experiences.map((experience) => {
          return (
            <TimelineItem
              key="001"
              dateText={experience.duration}
              dateInnerStyle={{
                background: experience.color,
                color: "#000",
                fontWeight: "600",
              }}
              bodyContainerStyle={{
                background: "#ddd",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)",
              }}
            >
              <h3 style={{ color: experience.color }}>{experience.title}</h3>
              <h4
                style={{
                  color: experience.color,
                  marginTop: "10px",
                  textDecoration: "none",
                }}
              >
                <a className="link" href={experience.company_url}>
                  {experience.company}
                </a>
              </h4>

              <ul className="description">
                {experience.description.map((desc) => {
                  return <li>{desc}</li>;
                })}
              </ul>
            </TimelineItem>
          );
        })}
      </Timeline>
    </>
  );

  // return (
  //   <div className="experience-accord">
  //     <ThemeProvider theme={theme.name === "light" ? LightTheme : DarkTheme}>
  //       <Accordion onChange={({ expanded }) => console.log(expanded)}>
  //         {props.sections.map((section) => {
  //           return (
  //             <Panel
  //               className="accord-panel"
  //               title={section["title"]}
  //               key={section["title"]}
  //             >
  //               {section["experiences"].map((experience) => {
  //                 return (
  //                   <ExperienceCard experience={experience} theme={theme} />
  //                 );
  //               })}
  //             </Panel>
  //           );
  //         })}
  //       </Accordion>
  //     </ThemeProvider>
  //   </div>
  // );
}

export default ExperienceAccordion;

/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello World 👋",
  title2: "Arpit Bindal",
  logo_name: "Arpit.b()",
  nickname: "Arpit / Bindal",
  full_name: "Arpit Bindal",
  subTitle: [
    "Full Stack Developer",
    "Frontend Designer",
    "UI/UX Professional",
    "Project Leader",
  ],
  resumeLink:
    "https://docs.google.com/document/d/1A_jWRG74Rst427tg1izLa6vRXclS9_9F856jWJPJlDY/edit?usp=sharing",
  mail: "mailto:contact@arpitbindal.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/arpitbindal",
  linkedin: "https://www.linkedin.com/in/arpitbindal/",
  gmail: "contact@arpitbindal.com",
  gitlab: "https://gitlab.com/harikanani",
  facebook: "https://www.facebook.com/harikrishan.kanani/",
  twitter: "https://twitter.com/Harikrushn9",
  instagram: "https://www.instagram.com/kanani_025/",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive Front end / User Interfaces for your web",
        "⚡ Building responsive website front end using ReactJS",
        "⚡ Developed high-functional web applications using Angular",
        "⚡ Creating application backend in Node, Express & Adonis",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "SASS",
          fontAwesomeClassname: "vscode-icons:file-type-scss2",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        // {
        //   skillName: "PHP",
        //   fontAwesomeClassname: "simple-icons:php",
        //   style: {
        //     color: "#7377AD",
        //   },
        // },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "logos-angular-icon",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "AdonisJS",
          fontAwesomeClassname: "simple-icons:adonisjs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        // {
        //   skillName: "GraphQL",
        //   fontAwesomeClassname: "simple-icons:graphql",
        //   style: {
        //     color: "#DE33A6",
        //   },
        // },
        // {
        //   skillName: "Android",
        //   fontAwesomeClassname: "simple-icons:android",
        //   style: {
        //     color: "#3DDC84",
        //   },
        // },
        // {
        //   skillName: "Java",
        //   fontAwesomeClassname: "simple-icons:java",
        //   style: {
        //     color: "#f89820",
        //   },
        // },
        // {
        //   skillName: "Flutter",
        //   fontAwesomeClassname: "simple-icons:flutter",
        //   style: {
        //     color: "#02569B",
        //   },
        // },
        // {
        //   skillName: "Dart",
        //   fontAwesomeClassname: "simple-icons:dart",
        //   style: {
        //     color: "#29B0EE",
        //   },
        // },
        // {
        //   skillName: "Visual Basic",
        //   fontAwesomeClassname: "simple-icons:dot-net",
        //   style: {
        //     color: "#029FCE",
        //   },
        // },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        // {
        //   skillName: "jQuery",
        //   fontAwesomeClassname: "simple-icons:jquery",
        //   style: {
        //     color: "#0865A6",
        //   },
        // },
        // {
        //   skillName: "Wordpress",
        //   fontAwesomeClassname: "simple-icons:wordpress",
        //   style: {
        //     color: "#207297",
        //   },
        // },
        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#CA1A22",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        // {
        //   skillName: "C",
        //   fontAwesomeClassname: "simple-icons:c",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        // {
        //   skillName: "C++",
        //   fontAwesomeClassname: "simple-icons:cplusplus",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        // {
        //   skillName: "Firebase",
        //   fontAwesomeClassname: "simple-icons:firebase",
        //   style: {
        //     color: "#FFCA28",
        //   },
        // },
        // {
        //   skillName: "PostgreSQL",
        //   fontAwesomeClassname: "simple-icons:postgresql",
        //   style: {
        //     color: "#336791",
        //   },
        // },
        // {
        //   skillName: "MongoDB",
        //   fontAwesomeClassname: "simple-icons:mongodb",
        //   style: {
        //     color: "#47A248",
        //   },
        // },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
    {
      title: "UI/UX Designing",
      fileName: "DesignImg",
      skills: [
        "⚡ Wireframing complex project layouts",
        "⚡ Designing and prototyping various web and mobile applications",
        "⚡ Experience with developing logos, illustrations and other assets",
      ],
      softwareSkills: [
        {
          skillName: "Figma",
          fontAwesomeClassname: "logos:figma",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "ci-adobe-xd",
          style: {
            color: "#BA278F",
          },
        },
        {
          skillName: "Photoshop",
          fontAwesomeClassname: "vscode-icons:file-type-photoshop",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Illustrator",
          fontAwesomeClassname: "cib-adobe-illustrator",
          style: {
            color: "#FFCA28",
          },
        },
      ],
    },
    {
      title: "Project Management",
      fileName: "ProjectManagImg",
      skills: [
        "⚡ 1.5 Yrs Experience as a team leader",
        "⚡ Managed, Planned and co-ordinated multiple projects",
        "⚡ Good communication and leadership skills",
      ],
      softwareSkills: [
        {
          skillName: "Slack",
          fontAwesomeClassname: "logos-slack-icon",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Trello (Kanban)",
          fontAwesomeClassname: "logos-trello",
          style: {
            color: "#BA278F",
          },
        },
        {
          skillName: "Documentation",
          fontAwesomeClassname: "bx-bxs-report",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Process Flow Models",
          fontAwesomeClassname: "carbon-model-builder",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Excel",
          fontAwesomeClassname: "vscode-icons:file-type-excel",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Task Management",
          fontAwesomeClassname: "fluent-clipboard-task-list-ltr-20-filled",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "BML Munjal University, Gurgaun",
      subtitle: "Bachelor of Technology (B.Tech - CSE)",
      logo_path:
        "https://s3.amazonaws.com/zaubatrademarks/5a76330f-0194-4197-9ecc-3258801ad372.png",
      alt_name: "SETI",
      duration: "2015 - 2019",
      descriptions: [
        "⚡ Studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        "⚡ Completed various online courses for Backend , Website Development, etc.",
        "⚡ Implemented several projects based on technologies learned during the course period",
      ],
      website_link: "https://www.bmu.edu.in/",
    },
    {
      title: "Delhi Public School, Jammu",
      subtitle: "Senior Secondary Education",
      logo_path:
        "https://www.eduvidya.com/admin/Upload/Schools/635859548424177799_Delhi%20Public%20School,%20Jammu.jpg",
      alt_name: "SETI",
      duration: "2013 - 2015",
      descriptions: [
        "⚡ Graduated with high academic qualifications",
        "⚡ Active participation in various extracurricular activities",
        "⚡ School Football team captain for 1 year ",
      ],
      website_link: "https://dpsjammu.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "M0001: MongoDB Basics",
      subtitle: "MongoDB University",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "MongoDB University",
      color_code: "#47A048",
    },
    {
      title: "1st Prize: BMU Pitchers",
      subtitle: "Entrepreneurship challenge (BMU)",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "entrepreneurship challenge",
      color_code: "#47A048",
    },
    {
      title: "RunnerUp: Planet Positive Challenge ",
      subtitle: "Hero Future Energies (HFE)",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "entrepreneurship challenge",
      color_code: "#47A048",
    },
    {
      title: "The Complete JS Course 2021",
      subtitle: "Udemy - Jonas Schmedtmann",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "entrepreneurship challenge",
      color_code: "#47A048",
    },
    {
      title: "Complete React Developer 2021",
      subtitle: "Udemy - Jonas Schmedtmann",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "entrepreneurship challenge",
      color_code: "#47A048",
    },
    {
      title: "Node.Js - Complete Bootcamp 2021",
      subtitle: "Udemy - Jonas Schmedtmann",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "entrepreneurship challenge",
      color_code: "#47A048",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I've completed one internship. I've mostly done projects on my own and I am actively looking for internships. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  experiences: [
    {
      title: "Full Stack Developer / Team Lead",
      company: "SMDEVOPS LLP",
      company_url: "https://smdevops.com/",
      logo_path: "valora.jpg",
      duration: "January 2020 - Current",
      key_technologies: [
        "simple-icons:react",
        "logos-angular-icon",
        "simple-icons:node-dot-js",
        "simple-icons:adonisjs",
      ],
      description: [
        "Created Highly functional and asthetic frontend for various multinational organisations using frameworks like angular and reactjs.",
        "Developed scalable and functional backend modules for various multi-tenant and funtionality intensive projects",
        "Responsible for managing complete project from start to delivery. Communicating directly with clients and handled a team of developers to deliver on their requirements.",
      ],
      // color: "#0071C5",
      color: "#61b8ff",
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "As a fullstack developer , my portfolio covers a list of backend and frontend projects. Note that these projects were either made in association with the organisation i was employed at or made on freelance basis, and hence are here to showcase my capabilities. I as an individual doesn't/cannot claim them as my property.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "ArpitBindal.jpeg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
    link: "https://twitter.com/Harikrushn9",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Netbookflix",
      url: "https://netbookflix.com/",
      description:
        "A complete Learning oriented platform, where publishers across world can upload content that then can be accessed by students across world. The platform comes with its own E-Book Reader, Audio and Video Player.",
      languages: [
        {
          name: "Angular",
          iconifyClass: "logos:angular-icon",
        },
        {
          name: "HTML",
          iconifyClass: "logos:html-5",
        },
        {
          name: "CSS",
          iconifyClass: "logos:css-3",
        },
        {
          name: "Node JS",
          iconifyClass: "logos:nodejs",
        },
      ],
    },
    {
      id: "1",
      name: "Adzumby",
      url: "https://adzumby.com/about",
      description:
        "A QR code based analysis and evaluation tool enabling its user to track and analyze, QR codes generated from the platform.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "Node JS",
          iconifyClass: "logos:nodejs",
        },
        {
          name: "Adonis JS",
          iconifyClass: "simple-icons:adonisjs",
        },
      ],
    },
    {
      id: "2",
      name: "Alrabeh Packs",
      url: "https://www.alrabehpack.com/",
      description:
        "An Informative and E-commerce enabled website for one of the biggest cardboard box manufacturers of middle east, the platform has a fully functional CMS with capabilities of manipulating products, images and view orders for its clients.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "Node JS",
          iconifyClass: "logos:nodejs",
        },
        {
          name: "Adonis JS",
          iconifyClass: "simple-icons:adonisjs",
        },
      ],
    },
    {
      id: "3",
      name: "Xhuma Clinic Management",
      url: "https://cmpremium.xhuma.co/#/login",
      description:
        "A complete multirole clinic management platform with capabilities like : appointment booking , invoice and bill generation , ticket generation , live chat and video conferencing.",
      languages: [
        {
          name: "Angular",
          iconifyClass: "logos:angular-icon",
        },
        {
          name: "HTML",
          iconifyClass: "logos:html-5",
        },
        {
          name: "CSS",
          iconifyClass: "logos:css-3",
        },
        {
          name: "Laravel",
          iconifyClass: "logos:laravel",
        },
      ],
    },
    {
      id: "4",
      name: "Dinners INN",
      url: "https://dinner-in.netlify.app/",
      description:
        "A Restaurant management system, where users can order food directly by scanning the QR code on the table. Admin can manipulate prices, menu, cost and set custom messages for order and payment confirmation",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "Node JS",
          iconifyClass: "logos:nodejs",
        },
        {
          name: "Adonis JS",
          iconifyClass: "simple-icons:adonisjs",
        },
      ],
    },
    {
      id: "5",
      name: "Quid",
      url: "https://quid.netlify.app/",
      description:
        "A training based platform where organisations can find and buy, courses benficial for its employeed from the long list of training centers across the country of philippines",
      languages: [
        {
          name: "Angular",
          iconifyClass: "logos:angular-icon",
        },
        {
          name: "HTML",
          iconifyClass: "logos:html-5",
        },
        {
          name: "CSS",
          iconifyClass: "logos:css-3",
        },
        {
          name: "Laravel",
          iconifyClass: "logos:laravel",
        },
      ],
    },
    // {
    //   id: "4",
    //   name: "node-web-scrapper",
    //   url: "https://github.com/harikanani/node-web-scrapper",
    //   description:
    //     "A Simple web scrapper that scrap the information of amazon products such as price.It also scrap Wikipedia Data such as birthdate.",
    //   languages: [
    //     {
    //       name: "HTML5",
    //       iconifyClass: "vscode-icons:file-type-html",
    //     },
    //     {
    //       name: "CSS3",
    //       iconifyClass: "vscode-icons:file-type-css",
    //     },
    //     {
    //       name: "NodeJs",
    //       iconifyClass: "logos-nodejs",
    //     },
    //   ],
    // },
    // {
    //   id: "5",
    //   name: "harikanani.github.io",
    //   url: "https://github.com/harikanani/harikanani.github.io",
    //   description:
    //     "A Personal Portfolio Website that showcases my work and experience. which is hosted on Github Pages.",
    //   languages: [
    //     {
    //       name: "HTML5",
    //       iconifyClass: "vscode-icons:file-type-html",
    //     },
    //     {
    //       name: "CSS3",
    //       iconifyClass: "vscode-icons:file-type-css",
    //     },
    //     {
    //       name: "JavaScript",
    //       iconifyClass: "logos-javascript",
    //     },
    //     {
    //       name: "SCSS",
    //       iconifyClass: "vscode-icons:file-type-scss2",
    //     },
    //   ],
    // },
    // {
    //   id: "6",
    //   name: "Automate Attendace",
    //   url: "https://github.com/harikanani/Node_Python",
    //   description: "Automation of Online Attendance using node js and python",
    //   languages: [
    //     {
    //       name: "JavaScript",
    //       iconifyClass: "logos-javascript",
    //     },
    //     {
    //       name: "Python",
    //       iconifyClass: "logos-python",
    //     },
    //   ],
    // },
    // {
    //   id: "7",
    //   name: "Automate Discord Bot",
    //   url: "https://github.com/harikanani/AutomateDiscordBot",
    //   description:
    //     "A Discord Bot to send Automatic messages to serer channel in regular time difference.",
    //   languages: [
    //     {
    //       name: "Python",
    //       iconifyClass: "logos-python",
    //     },
    //     {
    //       name: "Python Selenium",
    //       iconifyClass: "logos-selenium",
    //     },
    //     {
    //       name: "Chromium Browser",
    //       iconifyClass: "openmoji-chromium",
    //     },
    //   ],
    // },
    // {
    //   id: "8",
    //   name: "Flask Blog",
    //   url: "https://github.com/harikanani/flask_blog",
    //   description: "A Simple Blog Web Application made using Flask Framework",
    //   languages: [
    //     {
    //       name: "Python",
    //       iconifyClass: "logos-python",
    //     },
    //     {
    //       name: "Flask",
    //       iconifyClass: "cib-flask",
    //     },
    //     {
    //       name: "HTML5",
    //       iconifyClass: "vscode-icons:file-type-html",
    //     },
    //     {
    //       name: "CSS3",
    //       iconifyClass: "vscode-icons:file-type-css",
    //     },
    //   ],
    // },
    // {
    //   id: "9",
    //   name: "Netflix top series",
    //   url: "https://github.com/harikanani/netflix-top-series",
    //   description: "List of Top Netflix Series which is deployed to vercel.",
    //   languages: [
    //     {
    //       name: "JavaScript",
    //       iconifyClass: "logos-javascript",
    //     },
    //     {
    //       name: "ReactJS",
    //       iconifyClass: "logos-react",
    //     },
    //     {
    //       name: "HTML5",
    //       iconifyClass: "logos-html-5",
    //     },
    //     {
    //       name: "CSS3",
    //       iconifyClass: "logos-css-3",
    //     },
    //   ],
    // },
    // {
    //   id: "10",
    //   name: "COVID-19 Tracker",
    //   url: "https://github.com/harikanani/Covid19TrackerReact",
    //   description:
    //     "Simple Covid-19 Tracker made using React and deployed to Vercel.",
    //   languages: [
    //     {
    //       name: "JavaScript",
    //       iconifyClass: "logos-javascript",
    //     },
    //     {
    //       name: "ReactJS",
    //       iconifyClass: "logos-react",
    //     },
    //     {
    //       name: "HTML5",
    //       iconifyClass: "logos-html-5",
    //     },
    //     {
    //       name: "CSS3",
    //       iconifyClass: "logos-css-3",
    //     },
    //   ],
    // },
    // {
    //   id: "11",
    //   name: "Food Order Static Website",
    //   url: "https://github.com/harikanani/food-order-website",
    //   description:
    //     "A simple static website related to food restaurants service. this is reasponsive as well.",
    //   languages: [
    //     {
    //       name: "HTML5",
    //       iconifyClass: "logos-html-5",
    //     },
    //     {
    //       name: "CSS3",
    //       iconifyClass: "logos-css-3",
    //     },
    //     {
    //       name: "JavaScript",
    //       iconifyClass: "logos-javascript",
    //     },
    //     {
    //       name: "PHP",
    //       iconifyClass: "logos-php",
    //     },
    //   ],
    // },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
